import React, { useEffect, useState } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import ExamHeading from './ExamHeading';
import ExamsTab from './ExamTabExams';
import QuestionsTab from './ExamTabQuestions';
import AnswerKeyTab from './ExamTabAnswerKey';
import ExamModal from './ExamModal';
import CopyYearModal from './CopyYearModal';
import * as Act from '../../store/actions/actionCreators';
import * as API from '../../store/actions/fetchActions';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

const Exam = () => {
  const [examInput, setExamInput] = useState({});
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch();

  // Redux selectors
  const activeTab = useSelector(state => state.exam.activeTab);
  const modalOpen = useSelector(state => state.exam.modalOpen);
  const year = useSelector(state => state.exam.year);
  const years = useSelector(state => state.exam.years);
  const exam = useSelector(state => state.exam.exam);
  const exams = useSelector(state => state.exam.exams);
  const pagination = useSelector(state => state.exam.pagination);
  // const loading = useSelector(state => state.exam.loading);
  const [loading, setLocalLoading] = useState(false);


  // Update the cursor using native JS while loading
  useEffect(() => {
    document.body.style.cursor = loading ? 'wait' : 'default';
    return () => { document.body.style.cursor = 'default'; };
  }, [loading]);

  // Helper function to fetch exam data for a given page.
  // Returns a promise so we can wait for the API call to complete.
  const fetchData = (pageToLoad) => {
    setLocalLoading(true);
    const selectedYear = year || (years && years[0]);
    return dispatch(API.fetchExams(selectedYear, pageToLoad, pageSize))
    .then(() => {
      setLocalLoading(false);
    })
    .catch(() => {
      setLocalLoading(false);
    });
  }

  // On initial load and when the year changes, reset to page 1 and fetch data.
  useEffect(() => {
    setPage(1);
    fetchData(1);
  }, [year, years, dispatch]);

  // Sorting logic (unchanged)
  useEffect(() => {
    if (!exam.sorted) {
      dispatch(Act.sortExamTab('exams', exam.column ? exam.column : 'test_id'));
    }
  }, [exam.sorted, exam.column, dispatch]);

  // Modal toggle function for editing and copying
  const modalToggle = (e, type, id) => {
    e.preventDefault();
    switch (type) {
      case 'exam': {
        const selectedExam = exams.find(item => item.exam_id === id);
        setExamInput({
          ...selectedExam,
          online_exam_data: selectedExam.online_exam_data || [],
        });
        dispatch(Act.setExamModal({ main: 'exam' }));
        break;
      }
      case 'copy':
        dispatch(Act.setExamModal({ main: 'copy' }));
        break;
      default:
        dispatch(Act.setExamModal({ main: null }));
    }
  };

  // Handler for radio button exam selection
  const handleSelect = event => {
    const choice = parseInt(event.target.value, 10);
    if (choice !== exam.exam_id) {
      dispatch(Act.setExamSelect(choice));
    }
  };

  // Handler for adding a new exam
  const handleAddExam = () => {
    setExamInput({
      exam_id: -1,
      school_year: year,
      exam_name: '',
      test_id: 0,
      discipline: '',
      release_year: year,
      owned_by: 'PES',
      free_response_quantity: 0,
      free_response_time_limit: null,
      free_response_url: null,
      multiple_choice_quantity: 0,
      multiple_choice_time_limit: null,
      multiple_choice_url: null,
      preset_comments_required: false,
      resources: [],
      online_exam_data: [],
    });
    dispatch(Act.setExamModal({ main: 'exam' }));
  };

  // Handler for year changes
  const onYearChange = evt => {
    dispatch(Act.setExamYear(evt.target.value));
    // When year changes, reset to page 1 and fetch data
    fetchData(1);
  };

  const isViewingHistory = years && Math.max(...years) !== year;
  const examTab = activeTab === 'exams';
  const questionTab = activeTab === 'questions';
  const answerTab = activeTab === 'answers';

  // Pagination handlers update the page only after the API call returns
  const handlePrevPage = () => {
    if (page > 1 && !loading) {
      setLocalLoading(true);
      const newPage = page - 1;
      fetchData(newPage).then(() => {
        setPage(newPage);
        setLocalLoading(false)
      });
    }
  };

  const handleNextPage = () => {
    if (pagination && page < pagination.total_pages && !loading) {
      setLocalLoading(true);
      const newPage = page + 1;
      fetchData(newPage).then(() => {
        setPage(newPage);
        setLocalLoading(false);
      });
    }
  };

  return (
    <>
      <ExamHeading
        year={year}
        onChange={onYearChange}
        modalToggle={modalToggle}
        exam={exam}
        handleExam={handleAddExam}
      />
      <Row>
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: examTab })}
                onClick={() => dispatch(Act.setExamTab('exams'))}
              >
                Exams
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: questionTab })}
                onClick={() => dispatch(Act.setExamTab('questions'))}
              >
                FR Questions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: answerTab })}
                onClick={() => dispatch(Act.setExamTab('answers'))}
              >
                MC Answer Keys
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="exams">
              {/* While loading, the cursor shows "wait" and no UI change until data is fetched */}
              <ExamsTab
                exam={exam}
                exams={exams}
                canCopy={isViewingHistory}
                onSelect={handleSelect}
                modalToggle={modalToggle}
              />
              <div className="pagination-controls d-flex align-items-center mt-2">
                <button
                  onClick={handlePrevPage}
                  disabled={page <= 1 || loading}
                  className="btn btn-sm btn-outline-primary"
                >
                <span>{loading ? 'Loading...' : "Back"}</span>
                </button>
                <span className="mx-2 d-flex align-items-center">
                  Page&nbsp;
                  <select
                    className="form-control form-control-sm mx-1"
                    value={page}
                    onChange={e => {
                      const newPage = parseInt(e.target.value, 10);
                      setPage(newPage);
                      fetchData(newPage);
                    }}
                  >
                    {Array.from(
                      { length: pagination && pagination.total_pages ? pagination.total_pages : 1 },
                      (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      )
                    )}
                  </select>
                  {/* &nbsp; of {pagination ? pagination.total_pages : 1} */}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={pagination ? page >= pagination.total_pages || loading : true}
                  className="btn btn-sm btn-outline-primary"
                >
                  <span>{loading ? 'Loading...' : "Next"}</span>
                </button>
              </div>
            </TabPane>
            <TabPane tabId="questions">
              <QuestionsTab exam_id={exam.exam_id} />
            </TabPane>
            <TabPane tabId="answers">
              <AnswerKeyTab exam_id={exam.exam_id} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <ExamModal
        isOpen={modalOpen === 'exam'}
        toggle={modalToggle}
        onChange={data => setExamInput({ ...examInput, ...data })}
        onSave={data => dispatch(API.saveExam(data))}
        onDelete={data => dispatch(API.deleteExam(data))}
        saving={!!exam.saving}
        deleting={!!exam.deleting}
        {...examInput}
      />
      <CopyYearModal isOpen={modalOpen === 'copy'} toggle={modalToggle} />
    </>
  );
};

export default Exam;
